<template>
  <b-card
    no-body
    class="card-payment"
  >
    <b-card-body>
      <!-- form -->
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Summary"
              label-for="summary"
              class="mb-2"
            >
              <b-form-checkbox
                v-model="data.isSummary"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Date From"
              label-for="date-from"
              class="mb-2"
            >
              <flat-pickr
                v-model="data.from"
                class="form-control"
                :config="{ dateFormat: 'd/m/Y', enableTime: false }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Date To"
              label-for="date-to"
              class="mb-2"
            >
              <flat-pickr
                v-model="data.to"
                class="form-control"
                :config="{ dateFormat: 'd/m/Y', enableTime: false }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Taxi List"
              label-for="taxi-list"
            >
              <v-select
                :clearable="false"
                :options="taxiList"
                input-id="taxi-list"
                label="licenceNum"
                multiple
                :reduce="(taxi) => taxi.id"
                v-model="data.taxiIds"
              >
                <template #option="{ firstName, lastName, licenceNum }">
                  <span>
                    <span class="font-weight-bolder">{{ licenceNum }}</span>
                    -
                    <small>{{ `${firstName} ${lastName}` }}</small>
                  </span>
                </template>

                <template #selected-option="{ firstName, lastName, licenceNum }">
                  <span>
                    <span class="font-weight-bolder">{{ licenceNum }}</span>
                    -
                    <small>{{ `${firstName} ${lastName}` }}</small>
                  </span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              block
              variant="primary"
              @click="getStandingOrder()"
            >
              Get Standing Orders
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BFormGroup, BFormInput, BButton, BForm, BFormCheckbox
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api';
import flatPickr from 'vue-flatpickr-component'
import useStatistic from '../composables/useStatistic';
import useTaxi from '@/modules/taxi/composables/useTaxi';
import useUtils from '@/composables/useUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const { getTaxiList } = useTaxi();
    const { getStandingOrderReport } = useStatistic();
    const { convertDateToUTC } = useUtils();

    const data = ref({ from: new Date(), to: new Date(), taxiIds: [], isSummary: false });
    const taxiList = ref([]);

    const initData = async () => {
      const { taxis } = await getTaxiList();
      taxiList.value = taxis;
    }

    const getStandingOrder = async () => {
      const params = { ...data.value, from: convertDateToUTC(data.value.from), to: convertDateToUTC(data.value.to) }
      const response = await getStandingOrderReport(params);

      if (response !== null) {
        // create file link in browser's memory
        const url = URL.createObjectURL(response.data);

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    }

    initData();

    return {
      // Reactive
      data,
      taxiList,

      // Method
      getStandingOrder,
    };
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormCheckbox,
    flatPickr,
    vSelect,
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

#balance-table {
  min-height: 150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>