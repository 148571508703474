var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"card-payment",attrs:{"no-body":""}},[_c('b-card-body',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Summary","label-for":"summary"}},[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.data.isSummary),callback:function ($$v) {_vm.$set(_vm.data, "isSummary", $$v)},expression:"data.isSummary"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Date From","label-for":"date-from"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ dateFormat: 'd/m/Y', enableTime: false }},model:{value:(_vm.data.from),callback:function ($$v) {_vm.$set(_vm.data, "from", $$v)},expression:"data.from"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Date To","label-for":"date-to"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ dateFormat: 'd/m/Y', enableTime: false }},model:{value:(_vm.data.to),callback:function ($$v) {_vm.$set(_vm.data, "to", $$v)},expression:"data.to"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Taxi List","label-for":"taxi-list"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.taxiList,"input-id":"taxi-list","label":"licenceNum","multiple":"","reduce":function (taxi) { return taxi.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var licenceNum = ref.licenceNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(licenceNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s((firstName + " " + lastName)))])])]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var licenceNum = ref.licenceNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(licenceNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s((firstName + " " + lastName)))])])]}}]),model:{value:(_vm.data.taxiIds),callback:function ($$v) {_vm.$set(_vm.data, "taxiIds", $$v)},expression:"data.taxiIds"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.getStandingOrder()}}},[_vm._v(" Get Standing Orders ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }